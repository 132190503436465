import {
  Box,
  Button,
  Card,
  Container,
  LayoutVertical,
  Loader,
  Table,
  TableBody,
  TableCell,
  TableCellHead,
  TableCols,
  TableExpandContent,
  TableExpandTrigger,
  TableRow,
  Typography,
} from "@/components";
import { FetchContext as context } from "@/contexts/FetchContext";
import { removeAuthToken } from "@/contexts/JWTContext";
import { useNavigationError } from "@/hooks/useNavigationError";
import { useTableExpand } from "@/hooks/useTableExpand";
import { PointTransferSummary, pointTransferSummaryDefaultValues } from "@/types/pointTransferSummary";
import { axiosAccountInstance } from "@/utils/axios";
import { COLORS } from "@/utils/colors";
import { formatRequestDateTime, toCommaString } from "@/utils/formatter";
import { NavigateBefore, NavigateNext } from "@mui/icons-material";
import axios from "axios";
import { format } from "date-fns";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export const TransferSummary = () => {
  const { state } = useContext(context);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [pointTransferSummary, setPointTransferSummary] = useState<PointTransferSummary>(
    pointTransferSummaryDefaultValues
  );
  const [feeAmount, setFeeAmount] = useState(0);
  const { navigateError } = useNavigationError();
  const { triggerProps, contentProps } = useTableExpand();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const now = new Date();

  // チップ付与開始日(2023/10/12)
  const tipGrantStartDate = new Date(2023, 9, 12);
  const { colSpan } = triggerProps("breakdown");

  const handleErrors = (error: any) => {
    if (axios.isAxiosError(error)) {
      navigateError({
        error: error,
        endpoint: "/api/point/transfer/summary",
        appName: "POINT_TRANSFER_SUMMARY",
        path: "points",
      });
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: need not to watch
  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      try {
        removeAuthToken();
        const response = await axiosAccountInstance.get(`/api/point/transfer/summary`, {
          params: {
            start_at: formatRequestDateTime(getStartOfYear(selectedYear)),
            end_at: formatRequestDateTime(getEndOfYear(selectedYear)),
          },
        });
        setPointTransferSummary(response.data);
        setFeeAmount(response.data.transfer_fee + response.data.error_fee);
      } catch (error) {
        handleErrors(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetch();
  }, [selectedYear]);

  const getEndOfYear = (year: number) => {
    if (year === now.getFullYear()) {
      return now;
    } else {
      return new Date(year, 11, 31, 23, 59, 59, 999);
    }
  };

  const getStartOfYear = (year: number) => {
    if (year === tipGrantStartDate.getFullYear()) {
      return tipGrantStartDate;
    }
    return new Date(year, 0, 1);
  };

  return (
    <Container>
      {state.loading || isLoading ? (
        <Loader />
      ) : (
        <>
          <Helmet title={t("POINT_TRANSFER_SUMMARY")} />

          <LayoutVertical>
            <Typography variant="h1" align="left">
              {t("POINT_TRANSFER_SUMMARY")}
            </Typography>
          </LayoutVertical>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              alignSelf: "stretch",
              padding: "24px 0px",
            }}
          >
            {selectedYear === tipGrantStartDate.getFullYear() ? (
              <Box sx={{ width: "64px", height: "48px" }} />
            ) : (
              <Button onClick={() => setSelectedYear(selectedYear - 1)} sx={{ display: "flex" }}>
                <NavigateBefore sx={{ width: "24px", height: "24px" }} />
              </Button>
            )}
            <Typography variant="h2">{selectedYear + t("YEAR")}</Typography>
            {new Date().getFullYear() === selectedYear ? (
              <Box sx={{ width: "64px", height: "48px" }} />
            ) : (
              <Button onClick={() => setSelectedYear(selectedYear + 1)} sx={{ display: "flex" }}>
                <NavigateNext sx={{ width: "24px", height: "24px" }} />
              </Button>
            )}
          </Box>

          <LayoutVertical>
            <Card>
              <Table>
                <TableCols sizes={[40, 60]} />
                <TableBody sx={{ mariginBottom: "16px" }}>
                  <TableRow>
                    <TableCellHead sx={{ padding: "16px 8px", fontSize: "18px" }}>{t("TRANSFER_AMOUNT")}</TableCellHead>
                    <TableCell sx={{ padding: "16px 8px", fontSize: "18px" }} fontStyle="weak" align="right">
                      {toCommaString(pointTransferSummary.transfer_point)}
                      {t("YEN")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCellHead
                      sx={{
                        padding: "16px 8px",
                        fontSize: "18px",
                        borderBottom: "none",
                      }}
                    >
                      {t("FEE_TOTAL")}
                    </TableCellHead>
                    <TableCell
                      sx={{
                        padding: "16px 8px",
                        fontSize: "18px",
                        borderBottom: "none",
                      }}
                      fontStyle="weak"
                      align="right"
                    >
                      {toCommaString(feeAmount)}
                      {t("YEN")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCellHead sx={{ height: "36px", padding: 0 }} />
                    <TableCell
                      sx={{
                        height: "36px",
                        padding: "0px 0px 16px 0px",
                        fontSize: "14px",
                        color: COLORS.TEXT_WEAK,
                      }}
                      align="right"
                    >
                      {t("TEN_PERCENT_TAX_RATE")}
                      {toCommaString(feeAmount * pointTransferSummary.tax_rate)}
                      {t("YEN")}
                    </TableCell>
                  </TableRow>
                  {feeAmount !== 0 && (
                    <>
                      <TableExpandTrigger label={t("FEE_BREAKDOWN")} {...triggerProps("breakdown")} />
                      <TableExpandContent {...contentProps("breakdown")}>
                        <Table>
                          <TableBody>
                            <TableRow sx={{ padding: "16px 8px" }}>
                              <TableCellHead>{t("REMITTANCE_FEE")}</TableCellHead>
                              <TableCell sx={{ padding: "16px" }} fontStyle="weak" align="right">
                                {toCommaString(pointTransferSummary.transfer_fee)}
                                {t("YEN")}
                              </TableCell>
                            </TableRow>
                            <TableRow sx={{ padding: "16px 8px", fontSize: "18px" }}>
                              <TableCellHead>{t("ERROR_FEE")}</TableCellHead>
                              <TableCell sx={{ padding: "16px" }} fontStyle="weak" align="right">
                                {toCommaString(pointTransferSummary.error_fee)}
                                {t("YEN")}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                colSpan={colSpan}
                                sx={{
                                  padding: "4px 0px",
                                  border: "none",
                                  color: COLORS.TEXT_WEAK,
                                  backgroundColor: COLORS.BG_01,
                                  fontSize: "14px",
                                  textAlign: "right",
                                }}
                              >
                                {t("FEE_WITH_TAX")}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableExpandContent>
                    </>
                  )}
                  <TableRow>
                    <TableCellHead bold sx={{ padding: "16px 8px", fontSize: "18px" }}>
                      {t("TOTAL")}
                    </TableCellHead>
                    <TableCell bold sx={{ padding: "16px 8px", fontSize: "20px" }} align="right">
                      {toCommaString(pointTransferSummary.amount)}
                      {t("YEN")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Box
                sx={{
                  borderBottom: "none",
                  textAlign: "center",
                  paddingTop: "16px",
                  fontSize: "14px",
                }}
              >
                <Box sx={{ color: COLORS.TEXT_WEAK }}>{format(now, "yyyy/MM/dd HH:mm")}</Box>
                {pointTransferSummary.go_merchant_name + "(" + pointTransferSummary.go_invoice_number + ")"}
              </Box>
            </Card>
          </LayoutVertical>
        </>
      )}
    </Container>
  );
};
