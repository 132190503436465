export type PointTransferSummary = {
  transfer_point: number;
  transfer_fee: number;
  tax_rate: number;
  error_fee: number;
  amount: number;
  go_invoice_number: string;
  go_merchant_name: string;
};

export const pointTransferSummaryDefaultValues = {
  transfer_point: 0,
  transfer_fee: 0,
  tax_rate: 0,
  error_fee: 0,
  amount: 0,
  go_invoice_number: "",
  go_merchant_name: "",
};
